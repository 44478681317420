.add-sims {
  .sim-selected {
    margin-right: 0;
  }

  .pagination {
    margin-top: 2rem;
  }

  .add-sim-controls {
  }

  .card {
    height: 100%;

    .vstack {
      height: 100%;

      .table-container {
        height: 100%;
        display: flex;
        flex-direction: column;

        .pagination {
          margin-top: auto;
          padding-top: 2rem;
        }
      }
    }
  }
}
