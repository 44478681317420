@import "../../custom";

.user-table {
  .table-container {
    margin-bottom: 0;
  }
  table {
    font-size: 15px;

    td {
      vertical-align: middle;
      button {
        display: flex;
        align-items: center;
        width: 100%;
        border: none;
        text-decoration: none;
        transition: background-color 0.2s;

        &:hover {
          background: #eee;
        }

        svg {
          margin-right: 0.5rem;
        }
      }

      button {
        font-size: 15px;
        font-weight: 600;

        &.delete {
          color: $danger !important;
        }
      }
    }
  }
}

.form-group.permissions {
  label {
    font-weight: 400;
  }
}
