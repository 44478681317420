@import "../../custom";

.edit-branding {
  padding: 2rem;

  form {
    margin-top: 0;
    margin-bottom: 3rem;
  }

  .image-upload-section {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    ::-webkit-file-upload-button {
      margin-right: 1rem;
    }

    input[type="file"] {
      width: 240px;
      padding-right: 1rem;
    }

    .notification {
      margin-left: 1rem;
      margin-right: 2rem;
    }

    .upload-button {
      margin-right: 2rem;
    }

    .upload-note {
      font-size: 12px;

      span {
        display: block;
      }
    }
  }

  .brand-selection {
    .preview-menu,
    .menu-bar-preview,
    //.primary-preview,
    .secondary-preview {
      display: flex;
      align-items: center;
      //justify-content: center;
      height: 90px;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    }

    .preview-menu {
      height: 56px;
      background: #cccccc;
      border-radius: 10px 10px 0 0;
      padding: 1rem 0.75rem;
      justify-content: space-between;
    }

    .menu-bar-preview {
      height: 66px;
      padding: 1rem 2rem;

      img {
        height: 40px;
      }

      div {
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        margin-left: 0.75rem;

        &:first-of-type {
          margin-left: 1.5rem;
        }

        svg {
          height: 25px;
          width: 25px;
          margin-right: 0.4rem;
        }
      }
    }

    .primary-preview {
      .preview-title {
        font-size: 32px;
        margin-right: 2rem;
      }
    }

    .form-group {
      display: flex;
      align-items: center;
    }

    .preview-body {
      display: flex;
      height: 300px;
      justify-content: center;
      align-items: center;
      background: $light;

      .secondary-preview {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 350px;
        max-width: 450px;
        height: 250px;
        border-radius: 10px 10px;
        overflow: hidden;
        background: white;

        .header {
          width: 100%;
          padding: 1rem;
          font-size: 1.5rem;
        }
        .body {
          padding: 1rem;
        }
        .footer {
          width: 100%;
          padding: 1rem;
          display: flex;
          justify-content: flex-end;
          border-top: 1px solid #dee2e6;

          button {
            margin: 0 0.5rem;
            pointer-events: none;
          }
        }
      }
    }

    input[type="color"],
    input[type="radio"] {
      margin-right: 0.5rem;
    }

    .edit-colour-group {
      color: $blue;

      input[type="color"] {
        position: absolute;
        width: 200px;
        opacity: 0;
        cursor: pointer;

        @media (max-width: 992px) {
          width: 100px;
          height: 4em;
        }

        & + label {
          font-weight: 600;

          svg {
            // Edit (pencil) icon
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}
