input.rstm-search {
  border: 1px solid #ced4da;
  border-radius: 3px;
  margin: 1.5rem auto;
}

.admin-card {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 180px;
  width: 200px;
  margin: 1rem;
  padding: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #f5f5f5;
  }

  .card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  span {
    display: block;
    margin-top: 1.5rem;
    font-size: 21px;
  }
}

.admin-dashboard {
  h3 {
    color: #343a40 !important;
    margin-bottom: 2rem;
    font-weight: 600;
  }
}
