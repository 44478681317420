/* Simuage.com default */

// Standdard colors
$secondary: #8f5325;
$success: #28a745;
$info: #7854e4;
$warning: #f1ab21;
$danger: #dc3545;
$dark: #343a40;
$darkish: #555;
$medium: #aaa;
$lightish: #ddd;
$light: #f3f5f7;
$white: #ffffff;
$blue: #005cc8;
// Default blue for .btn-links, etc.

//Custom colors to be mapped
$primary: #007bff;
$accent: #111111;
$accent-1: #232436;

$navbar-bg: $accent-1;
$navbar-icon: $primary;
$navbar-user-links: $light;
$toolbar-bg: $accent;
$toolbar-color: $light;
$body-bg: $light;
$body-color: $dark;
$table-head-bg: $primary;
$table-head-color: $light;
$table-head-icon: $dark;
$table-hover: lighten($primary, 70%);
$table-borders: $lightish;

// this is used to map colors. change accordingly.
$theme-colors: (
  primary: $primary,
  secondary: $accent,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  white: $white,
  // additional colours...
  accent: $accent,
  accent-1: $accent-1,
  navbar-bg: $navbar-bg,
  navbar-icon: $navbar-icon,
  navbar-user-links: $navbar-user-links,
  toolbar-bg: $toolbar-bg,
  toolbar-color: $toolbar-color,
  body-bg: $body-bg,
  body-color: $body-color,
  table-head-color: $table-head-bg,
  table-head-bg: $table-head-color,
  table-head-icon: $table-head-icon,
  table-hover: $table-hover,
  // The above will enable things like bg-accent,btn-accent to be called,,
);

:root {
  // Defining defaults
  --brand-primary: #007bff;
  --brand-primary-text: #fff;
  --brand-secondary: #7854e4;
  --brand-secondary-text: #fff;
  --brand-button: #007bff;
  --brand-button-text: #fff;

  // Assigning brand colours to elements
  --table-head-bg: var(--brand-secondary);
  --table-head-color: var(--brand-secondary-text);
  --table-head-icon: var(--brand-secondary-text);
  --toolbar-bg: var(--brand-primary);
  --toolbar-text: var(--brand-primary-text);
  --button-bg: var(--brand-primary);
  --button-text: var(--brand-primary-text);

  --table-hover: #eee;
}
