@import "../../custom";

$table-borders: #eee;
$table-controls-borders: #ced4da;

// Applying to all tables/forms
.form-check-label {
  font-weight: 700;
}

form {
  margin-top: 1.75rem;
}
.table {
  width: 100%;
  //border: 1px solid $table-borders;
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);

  &,
  * {
    border-color: $table-borders;
    text-align: left;
  }

  tr,
  td {
    border: 0;
    padding-top: 8px;
    padding-bottom: 0;
  }
  tbody {
    border-top: 2px solid #ddd !important;
  }
  th,
  td {
    white-space: nowrap;
  }

  border-spacing: 0;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}

.table-container-container {
  min-height: 250px;

  .table-container {
    margin-bottom: auto;

    table {
      margin-bottom: auto;
    }
  }
}

// Special class for making nicely styled tables: .fancy-table
// Adapated from, but different to, the MainTable
.table-group.fancy-table {
  display: flex;
  align-content: center;
  flex-direction: column;
  background: white;
  padding: 2rem 2rem 1rem 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
  font-size: 14px;

  .table-container {
    margin-bottom: 2rem;

    table {
      height: 100%;
      th {
        vertical-align: middle;
      }
    }
  }

  input,
  select {
    height: 40px;
    width: 50px;
    border: 1px solid $table-controls-borders;
    border-radius: 3px;
  }

  .search {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;

    .dropdown-item {
      font-size: 14px;
    }

    .btn-tiny {
      position: absolute;
      right: 45px;
      top: 50%;
      z-index: 101;
      transform: translateY(-50%);
      font-size: 1.125rem;
      color: $medium;
      background: transparent;
      border: none;
      padding: 0;
      width: 16px;
      height: 16px;
      line-height: 0;
    }

    input {
      width: 240px;
      padding: 0 0.5rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .details {
    text-align: center;
    padding-left: 1rem;

    input,
    select {
      margin-left: 6px;
    }
  }

  .pagination {
    margin: 2rem auto;

    input {
      padding: 0 4px;
      margin-left: 6px;
      width: 40px;
    }
    .current {
      background: var(--brand-primary);
      color: var(--brand-primary-text);
      cursor: default;
    }
    .spacer {
      text-align: center;
      width: 40px;
    }
    button {
      border-radius: 0;

      &:first-of-type {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-of-type {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }

      + button {
        border-left: none;
      }
    }
  }

  td {
    span.success,
    span.Success {
      color: $success;
    }
    span.danger,
    span.Failure {
      color: $danger;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    height: 40px;
    width: 40px;
    border: 1px solid $table-controls-borders;
    background: white;
    font-weight: 700;
    border-radius: 3px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  select {
    height: 40px;
    padding: 0 4px;
    border: 1px solid $table-controls-borders;
    border-radius: 3px;
    margin-left: 6px;
  }
}

// A simpler basic table without all the features of the .fancy-table
.table-container.basic-table {
  margin-bottom: 0;
  box-shadow: none;
  font-size: 14px;
  overflow-x: visible;

  table {
    thead {
      background: white;
      color: $dark;
      border: 1px solid #ddd !important;

      th {
        border-right: 1px solid #ddd !important;
      }
    }
  }

  &.checkboxed {
    // Adjusts the styling of the first cell of each row for checkboxes
    table {
      tr {
        td:first-of-type,
        th:first-of-type {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 100%;
        }
        th:first-of-type {
          margin-top: 4.5px; //  Half the difference between the heights of the <thead> and a <tr> (40px - 31px) - for alignment
        }
      }
    }
  }
  .search {
    input {
      width: 100%;
    }
  }
  .pagination {
    justify-content: center;

    button {
      height: 34px;
      width: 34px;
    }
  }
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;

  input,
  select {
    height: 40px;
    width: 50px;
    border: 1px solid $table-controls-borders;
    border-radius: 3px;
  }

  .dropdown-item {
    font-size: 14px;
  }

  .btn-tiny {
    position: absolute;
    right: 45px;
    top: 50%;
    z-index: 101;
    transform: translateY(-50%);
    font-size: 1.125rem;
    color: $medium;
    background: transparent;
    border: none;
    padding: 0;
    width: 16px;
    height: 16px;
    line-height: 0;
  }

  input {
    width: 240px;
    padding: 0 0.5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.sims-selected {
  height: 21px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0.625rem 0 0.625rem auto;

  .btn-tiny {
    margin-left: 0.375rem;
    margin-bottom: 0.25rem;
  }
}
.sim-table-container {
  overflow-y: auto;
  overflow-x: hidden;
}
