@import "../../custom.scss";

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  color: $darkish;

  .termsBtn {
    margin-left: 3px;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
      color: lighten($darkish, 10%);
    }
  }

  p {
    margin: 0;
  }
}
