@import "../../custom";

$light: #eee;
$dark: #222;

nav {
  padding-left: 12px;

  .dropdown-toggle {
    display: flex;
    align-items: center;
  }
  .icon {
    font-size: 20px;
    margin-right: 5px;
  }

  .username {
    margin-left: auto;

    span {
      display: flex;
      align-items: center;
    }
  }

  .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
  }

  .dropdown-item {
    transition: background-color 0.2s;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  hr.dropdown-divider {
    margin: 0;
  }

  h2 {
    margin-bottom: 0;
  }

  &.light {
    background: $light;
    color: $dark;

    .dropdown-toggle {
      color: $dark;
    }
    .dropdown-menu {
      background-color: $light;

      .dropdown-item {
        color: $dark;

        &:hover {
          background: darken($light, 10%);
        }
      }
    }
  }
  &.dark {
    background: $dark;
    color: $light;

    .navbar-brand {
      color: $light !important;
      font-weight: 600;
    }

    #logo {
      color: $light !important;
    }

    .dropdown-toggle {
      color: $light;
    }
    .dropdown-menu {
      background: $dark;

      .dropdown-item {
        color: $light;

        &:hover,
        &:active,
        &:focus,
        &:active:focus {
          background: lighten($dark, 10%);
        }
      }
    }
  }
}
