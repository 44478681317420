@import "../../../custom.scss";

.add-label {
  border: none !important;
  outline: none !important;
  font-size: 14px;
  padding: 0;
}
#label-input {
  box-sizing: border-box;
  border: 2px solid #ccc;
  background-color: #f3f5f7;
  border-radius: 4px;
}
#label-input:focus {
  border-color: #bfdeff;
  outline: 0 none;
}
.label-form {
  align-items: center;
}

.error-tooltip {
  position: absolute;
  background: $danger;
  padding: 2px 10px;
  color: white;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 600;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    margin-right: 0.5rem;
  }

  .button-container {
    *:first-of-type {
      margin-right: 0.5rem;
      font-size: 16px;
    }
  }
}

.sim-label-form-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;

    input {
      min-width: 100px;
      max-width: 125px;
    }
  }

  .button-container {
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      padding: 0;
      background: none;
      border: none;
      margin-left: 0.375rem;

      &.success {
        font-size: 20px;
      }
    }
  }
}

td:has(.add-label),
td:has(.sim-label-form-container) {
  height: 32px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
td:has(.label-container) {
  color: $body-color !important;
}
