// Dashboard styles

.dashboard-table {
  th {
    text-transform: capitalize;
  }
}

.pie-chart-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .pie-chart-container {
    background: #ddd;
    padding: 1.5rem;
    border-radius: 5px;
    max-width: 350px;
    margin: 1rem 2rem;

    h4 {
      margin-bottom: 1rem;
    }

    .recharts-legend-wrapper {
      // Moves legend up slightly
      top: -5px !important;
      text-transform: capitalize;
    }
  }
}
