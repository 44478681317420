@import "../../../custom.scss";

.toolbar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  height: 90px;
  padding: 12px 24px;
  background: var(--toolbar-bg);
  color: var(--toolbar-text);

  h2 {
    margin-right: 2rem;
  }

  .toolbarButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 64px;
    min-width: 64px;
    margin: auto 5px;
    padding: 0 8px;
    border: solid 1px $light;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0);
    color: var(--toolbar-text);
    font-size: 14px;
    font-weight: 400;
    transition: background-color 0.2s;
    pointer-events: all;

    &[disabled="disabled"],
    &:disabled {
      border-color: transparentize($light, 0.5) !important;
      color: transparentize($light, 0.5) !important;

      *:not(.custom-tooltiptext) {
        opacity: 0.5 !important;
      }

      background: transparent;
      cursor: not-allowed;
      &:hover {
        background: transparent;
      }
    }

    &.small {
      flex-direction: row;
      justify-content: flex-start;
      height: 30px;
      min-width: 95px;
      margin: 0 5px;

      & + .small {
        margin-top: 4px;
      }

      i {
        height: 18px;
        width: 18px;
        margin-bottom: 0;
        margin-right: 6px;
      }
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    i {
      height: 24px;
      width: 24px;
      margin-bottom: 6px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &[textcolour="light"] {
    color: white;

    .toolbarButton {
      border-color: white;
      color: white;

      &[action="bar"] {
        i {
          background-image: url("../../../images/icons/bar_light.svg");
        }
      }

      &[action="simSwap"] {
        i {
          background-image: url("../../../images/icons/simswap_light.svg");
        }
      }

      &[action="refresh"] {
        i {
          background-image: url("../../../images/icons/refresh_light.svg");
        }
      }

      &[action="export"] {
        i {
          background-image: url("../../../images/icons/export_light.svg");
        }
      }

      &[action="backToAccounts"] {
        i {
          background-image: url("../../../images/icons/building_light.svg");
        }
      }

      &[action="back"] {
        i {
          background-image: url("../../../images/icons/arrow_light.svg");
        }
      }
    }
  }

  // Note that black is used instead of $dark for better contrast with whatever the chosen brand colour is.
  &[textcolour="dark"] {
    color: black;

    .toolbarButton {
      border-color: black;
      color: black;

      &[action="bar"] {
        i {
          background-image: url("../../../images/icons/bar_dark.svg");
        }
      }

      &[action="simSwap"] {
        i {
          background-image: url("../../../images/icons/simswap_dark.svg");
        }
      }

      &[action="refresh"] {
        i {
          background-image: url("../../../images/icons/refresh_dark.svg");
        }
      }

      &[action="export"] {
        i {
          background-image: url("../../../images/icons/export_dark.svg");
        }
      }

      &[action="backToAccounts"] {
        i {
          background-image: url("../../../images/icons/building_dark.svg");
        }
      }

      &[action="back"] {
        i {
          background-image: url("../../../images/icons/arrow_dark.svg");
        }
      }

      // Disabled dark buttons
      &[disabled="disabled"],
      &:disabled {
        border-color: transparentize(black, 0.5) !important;
        color: transparentize(black, 0.5) !important;
      }
    }
  }
}
// Action modal windows - possibly should be replaced with general .modal rule

// Icons - should be replaced with FontAwesome
i.barred {
  height: 24px;
  width: 24px;
  margin-bottom: 6px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../images/icons/bar_light.svg");
}

// Tooltips
// Tooltips
/* Tooltip container */
.custom-tooltip {
  cursor: help;
  position: relative;
}

/* Tooltip text */
.custom-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $dark;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.16);
  /* Position the tooltip text - see examples below! */
  position: absolute;
  top: 110%;
  z-index: 1000;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-tooltip:disabled:hover .custom-tooltiptext {
  visibility: visible;
}

.table-tooltip {
  position: relative;
  cursor: help;

  svg {
    margin-left: 0.5rem;
    font-size: 1.25em;
  }
}
.table-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: $dark;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 80%;
  left: 50%;
  margin-left: -60px;
  white-space: normal;
  text-align: center !important;
  font-weight: 400;
  font-size: 14px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  line-height: 1.2;
  cursor: pointer;
}

/* Show the tooltip text when you mouse over the tooltip container */
.table-tooltip:hover .table-tooltiptext {
  visibility: visible;
}
