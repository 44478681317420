@import "../../custom";

nav {
  .top-nav-links {
    display: flex;
    margin-right: auto;
    margin-left: 1rem;

    .nav-link {
      display: inline-flex;

      font-size: 18px;
      transition: color 0.2s;
      transition: background-color 0.2s;
      border-radius: 5px;

      svg {
        height: 25px;
        width: 25px;
        margin: auto 0.4rem auto 0;
      }
    }
  }

  &.dark {
    .top-nav-links {
      .nav-link {
        color: $light;
        background: rgba(255, 255, 255, 0);

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
  &.light {
    .top-nav-links {
      .nav-link {
        color: $dark;
        background: rgba(0, 0, 0, 0);

        &:hover {
          background: rgba(0, 0, 0, 0.075);
        }
      }
    }
  }
}
