@import url("https://use.typekit.net/xde0oub.css");
@import "custom";
/* import bootstrap SASS */
@import "~bootstrap/scss/bootstrap";

// Global styles
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  font-style: normal;
}
h2 {
  margin-bottom: 0;
}

.font-weight-semibold {
  font-weight: 600;
}

body {
  background-color: $body-bg;
  color: $body-color;
  font-family: urw-form, sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.custom-primary {
  background-color: $accent !important;
  color: $light;
}

.success-icon {
  color: $success;
}
.failure-icon {
  color: $danger;
}

.text-success {
  color: $success;
  font-weight: 600;
}
.text-failure {
  color: $danger;
  font-weight: 600;
}

.progress-bar {
  background: var(--brand-primary);
}

.info {
  color: $primary;
  font-weight: 600;
}
.success {
  color: $success;
  font-weight: 600;
}
.danger {
  color: $danger;
  font-weight: 600;
}

// Buttons
.btn {
  transition: all 0.2s !important;
  //height: 40px;

  &.btn-light {
    background: $lightish;
    border-color: $lightish;

    &:hover {
      background: lighten($lightish, 5%);
    }
  }

  &.btn-link {
    color: var(--brand-primary) !important;
  }

  &.small {
    font-size: 15px;
    padding: 0 8px;
  }
}

.btn-modal-close:hover {
  color: var(--brand-secondary-text);
  opacity: 0.9;
}

.btn-primary {
  background-color: var(--button-bg) !important;
  border-color: var(--button-bg) !important;
  color: var(--button-text) !important;
  opacity: 1;

  &:hover {
    opacity: 0.9;
  }
}

.btn-secondary {
  background: var(--brand-secondary) !important;
  border-color: var(--brand-secondary) !important;
  color: var(--brand-secondary-text) !important;
  opacity: 1;

  &:hover {
    opacity: 0.9;
  }
}

.btn-default {
  background: rgb(61, 61, 61) !important;
  color: white !important;
  border-color: rgb(61, 61, 61) !important;
  opacity: 1;

  &:hover {
    opacity: 0.9;
  }
}

.btn-sm {
  font-weight: 600 !important;
}

.btn.btn-modal-close {
  font-size: 2rem;
  line-height: 2rem;
  color: var(--brand-secondary-text);
  padding: 0rem;
  transition: opacity 0.2s;
  opacity: 1;

  &:hover {
    opacity: 0.9;
  }
}

.btn-tiny {
  font-size: 1.125rem;
  color: $medium;
  background: transparent !important;
  border: none;
  padding: 0;
  width: 16px;
  height: 16px;
  line-height: 0;
}

// Spinner on the buttons styling
.btn {
  .spinner-grow {
    margin-right: 0.3rem;
    vertical-align: 0;
  }
  .spinner-grow-sm {
    width: 0.6rem;
    height: 0.6rem;
  }
}

// Generic loading indicator
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;

  span:first-of-type {
    margin-right: 0.5rem;
  }
}

// Form validation

input.error {
  border-color: $danger;
}

.input-feedback {
  color: $danger;
  margin-top: -12px;
  font-size: 12px;
  margin-bottom: 15px;
}

.error-success {
  text-align: center;
  padding: 0.75rem;

  h5 {
    font-size: 1.375rem;
    margin-top: 0.375rem;
  }
  p {
    margin-bottom: 0;
  }
}
.error-alert {
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
}

// Modals
.modal-header {
  background-color: var(--brand-secondary);
  color: var(--brand-secondary-text);
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

.actions {
  table {
    max-height: 200px;
    margin-bottom: 1rem;

    th {
      padding-bottom: 0.25rem;
    }
    tbody {
      font-size: 15px;
      max-height: 200px;
      overflow-y: auto;
      display: block;
    }
  }
  .form-check-label {
    font-weight: 700;
  }

  form {
    margin-top: 1.75rem;
  }
}

.sim-swap {
  .input-group {
    input {
      z-index: 3 !important;
    }
    button {
      border-top-right-radius: 0.25rem !important;
      border-bottom-right-radius: 0.25rem !important;
    }
  }
}

.table-container {
  position: relative;
  min-height: 200px;
  max-width: 100vw;
  max-height: calc(100vh - 420px); // roughly the height of the header/toolbar/controls
  overflow-x: auto;
  margin-bottom: 3rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);

  &.loading {
    overflow-y: hidden;
  }

  table {
    width: 100%;
    border: 1px solid $table-borders;
    min-width: 220px;

    &,
    * {
      border-color: $table-borders;
      text-align: left;
    }

    thead {
      position: sticky;
      top: -1px;
      height: 40px;
      background: var(--table-head-bg);
      color: var(--table-head-color);
      z-index: 100;

      &,
      * {
        border: none !important;
      }
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      white-space: nowrap;
    }

    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 5px 7px;
      border-bottom: 1px solid $table-borders;
      border-right: 1px solid $table-borders;

      :last-child {
        border-right: 0;
      }
    }

    th {
      svg {
        margin-bottom: 4px;
        margin-left: 4px;
      }

      &.right {
        text-align: right;
      }
    }
  }

  .loading,
  .no-sims {
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    height: calc(100% - 40px);
    width: 100%;
    background: white;
    color: $dark;
    border: 1px solid $table-borders;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    position: absolute;
    top: calc(50% + 20px); // 20px is half the height of the thead
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    text-align: center;

    span {
      margin-left: 0.75rem;
    }
  }

  .no-results {
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    height: calc(100% - 40px);
    width: 100%;
    background: transparent; // I've made this transparent so that we can see if this ever pops up with results visible, which it obviously shouldn't
    color: $dark;
    border: 1px solid $table-borders;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    position: absolute;
    top: calc(50% + 20px); // 20px is half the height of the thead
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;

    span {
      margin-left: 0.75rem;
    }
  }
}

.tabs {
  position: relative;
  top: 1px;
  padding-left: 0;
  z-index: 2;

  .tab {
    padding: 0.5rem 1rem;
    border-radius: 5px 5px 0 0;
    background: lightgray;
    border: none;
    margin-right: 4px;

    &.active {
      background: var(--brand-secondary);
      color: var(--brand-secondary-text);
      font-weight: 700;
    }
  }
}

form {
  .form-group {
    margin-bottom: 1rem;
  }
}

.PortalButtons {
  padding-top: 0.2rem;
  display: flex;
  align-items: right;
  .btn-link {
    text-decoration: none;
    color: $blue !important;
  }
}

.rstm-tree-item {
  border-bottom-color: #f3f5f7 !important ;
  list-style-type: none;
}
